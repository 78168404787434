var component = require('../../lib/js/component');
var utils = require('../../lib/js/utils');
var ScrollMagic = require('scrollmagic');
require('../../vendor/bower_components/gsap/src/uncompressed/TweenLite');
require('../../vendor/bower_components/gsap/src/uncompressed/plugins/CSSPlugin');
require('../../vendor/custom-plugin/typeahead.jquery.js');

var GlobalNavbar = function() {
    this.defaults = {
        sticky: false, // does the menu stick to the top of the browser
        collapseMenuOnScroll: false, // should the menu automatically collapse to the hamburger menu when scrolling down
        showMenuOnLoad: true, // should the menu be visible by default when the page loads
        secondaryNav: false, // do we have a campaign or page-specific secondary nav
        secondaryNavIsSticky: false, // should the secondary nav be sticky?
        transparent: false, // should the nav start off as transparent?
        textColor: null, // what colour should the text be (if transparent)
        bgColor: 'purple' // the bg colour of the nav (will transition to this colour if its initial state is transparent)
    };
    this.settings = {};
    this.$navCtn = null;
    this.$globalNav = null;
    this.$body = null;
    this.$html = null;
    this.$htmlBody = null;
    this.$window = null;
    this.$hamburger = null;
    this.$mainNav = null;
    this.$mainNavLinks = null;
    this.$logoAnimation = null;
    this.$logoStatic = null;
    this.$secondaryNav = null;
    this.scrollMagicController = null;
    this.scrollMetrics = {prevScrollDir: null, initScrollPos: 0, prevTick: 0};
    this.forcedMenuState = null;
    this.winWidth = 0;
    this.BREAKPOINT = 991;
    this.LOGO_ANIM_DUR = 1300;
    this.FORCE_COLLAPSE_MENU_BELOW = 150;
    this.FORCE_SHOW_MENU_ABOVE = 100;
    this.SCROLL_SPEED_TRIGGER = 1000; // the minimum speed (in pixels/second) the user must scroll to trigger the menu to show/hide
    this.SCROLL_AMOUNT_TRIGGER = 500; // the minimum distance (in pixels) the user must scroll to trigger the menu to show/hide
    


    // Initialize
    this.initialize = function( element, options ) {
        this.$navCtn = this.$element;
        this.$globalNav = $('#global-navbar');
        this.$secondaryNav = this.$navCtn.find('.secondary-nav-ctn');
        this.$body = $('body');
        this.$html = $('html');
        this.$htmlBody = $('html,body');
        this.$window = $(window);
        this.$hamburger = $("#hamburger");
        this.$mainNav = $("#main-menu");
        this.$mainNavLinks = this.$mainNav.find('>li');
        this.$logoAnimation = $('#logo-ani');
        this.$logoStatic = $('#logo-static');
        this.scrollMagicController = new ScrollMagic.Controller();
        
        this.initSettings();
        this.initLogo();
        this.initMainNav();

        if(this.$secondaryNav !== null && this.settings.secondaryNavIsSticky) {
            this.initSecondaryNav();
        }

        this.onResize();
        this.$window.resize(this.onResize.bind(this));

        this.$navCtn.addClass('loaded');
    };


    this.initSettings = function() {
        // this.settings = $.extend({}, this.defaults, options || {});
        
        this.settings.sticky = this.$navCtn.data('sticky');
        this.settings.collapseMenuOnScroll = this.$navCtn.data('collapseMenuOnScroll');
        this.settings.showMenuOnLoad = this.$navCtn.data('showMenuOnLoad');
        this.settings.secondaryNav = this.$navCtn.data('secondaryNav');
        this.settings.secondaryNavIsSticky = this.$navCtn.data('secondaryNavSticky');
        this.settings.transparent = this.$navCtn.data('transparent');
        this.settings.textColor = this.$navCtn.data('textColor');
        this.settings.bgColor = this.$navCtn.data('bgColor');

        // Some settings don't make sense when others are set to certain values
        
        // If there's a secondary nav and it's sticky, it doesn't make sense to also have the main nav be sticky.
        if(this.settings.secondaryNav !== false && this.settings.secondaryNavIsSticky) {
            this.settings.sticky = false;
        }

        // If the nav isn't sticky, then there's no point in collapsing it on scroll
        if(this.settings.sticky === false) {
            this.settings.collapseMenuOnScroll = false;
        }
    };



    // Initialize the main logo
    this.initLogo = function() {
        // Reference to debounced function to animate the logo
        var cleanHover = utils.debounce(this.animateLogo.bind(this), this.LOGO_ANIM_DUR, true);

        // If on touch-device or we can't show CSS animations, just show the static logo
        if(this.$html.hasClass('touch') || this.$html.hasClass('no-cssanimations')) {
            this.$logoAnimation.addClass('hidden');
            this.$logoStatic.css('display','block');
        
        // Otherwise, animate the logo upon mouse hover
        } else {
            this.$logoAnimation.addClass('animating')
                .on('mouseenter', cleanHover);
        }
    };



    // Animate the logo
    this.animateLogo = function() {
        this.$logoAnimation.removeClass('stoppedAnimation').addClass('hoverPlay');

        setTimeout(function() {
          this.$logoAnimation.removeClass('hoverPlay').addClass('stoppedAnimation');
        }.bind(this), this.LOGO_ANIM_DUR);
    };



    // Init all functionality for the main nav
    this.initMainNav = function() {
        var scene;

        if(this.settings.sticky) {
            this.$navCtn.addClass('sticky');
        }

        if(this.settings.showMenuOnLoad) {
            this.toggleShowMenu(true);
        }

        if(this.settings.collapseMenuOnScroll) {
            this.getDocHeight();
            scene = new ScrollMagic.Scene({duration: this.getDocHeight.bind(this), triggerHook:'onLeave'})
                .on('progress', this.onScrollProgress.bind(this))
                .addTo(this.scrollMagicController);
        }

        if(this.settings.transparent !== true) {
            this.$globalNav.addClass(this.settings.bgColor + '-bg');
        } else {
            this.$navCtn.addClass('transparent-' + this.settings.textColor);

            // If we have a sticky-secondary nav or the main nav is sticky, fade on the bg colour when scrolling
            if(this.settings.sticky || this.settings.secondaryNavIsSticky) {
                scene = new ScrollMagic.Scene({offset:110, triggerHook:'onLeave'})
                    .on('enter', function() {
                        if(this.settings.secondaryNavIsSticky !== true) {
                            this.$globalNav.addClass(this.settings.bgColor + '-bg');    
                        }
                        this.$navCtn.removeClass('transparent-white').removeClass('transparent-black').addClass('absolute');
                    }.bind(this))
                    .on('leave', function() {
                        if(this.settings.secondaryNavIsSticky !== true) {
                            this.$globalNav.removeClass(this.settings.bgColor + '-bg');
                        }
                        this.$navCtn.addClass('transparent-' + this.settings.textColor).removeClass('absolute');
                    }.bind(this))
                    .addTo(this.scrollMagicController);    
            }
            
        }

        this.$hamburger.on('click keydown', this.onClickHamburger.bind(this));
        this.$mainNavLinks.hover(this.onHoverMainNavLink.bind(this));
        this.$mainNavLinks.click(this.onClickMainNavLink.bind(this));
    };



    // Initiate the secondary nav functionality
    this.initSecondaryNav = function() {
        var scene = new ScrollMagic.Scene({triggerHook:'onLeave', triggerElement:this.$secondaryNav[0], duration:0})
            .setPin(this.$secondaryNav[0])
            .addTo(this.scrollMagicController);
    };



    // User clicked the hamburger icon
    this.onClickHamburger = function(e) {
        if(e) {
            e.preventDefault();
        }

        if(e === undefined || e.keyCode === undefined || e.keyCode === 13 || e.keyCode === 32) {
            this.toggleShowMenu();
        }
    };


    // Hover on a main link in the nav
    this.onHoverMainNavLink = function(e) {
        if(this.winWidth < this.BREAKPOINT) return;

        switch(e.type) {
            case 'mouseenter':
                $(e.currentTarget).addClass('open');
                this.$navCtn.addClass('submenu-open');
                break;
            case 'mouseleave':
                $(e.currentTarget).removeClass('open');
                this.$navCtn.removeClass('submenu-open');
                break;
        }
    };



     // Click on a main link in the nav
    this.onClickMainNavLink = function(e) {
        this.$mainNavLinks.removeClass('open');
        $(e.currentTarget).addClass('open');
        this.$navCtn.addClass('submenu-open');
    };



    // Show/hide the menu
    this.toggleShowMenu = function( show ) {
        if(show === null || show === undefined) {
            show = !this.$navCtn.hasClass('show-menu');
        }
        this.$navCtn.toggleClass('show-menu', show);

        if(show) {
            this.$body.addClass('no-scroll');
        } else {
            this.$mainNavLinks.removeClass('open');
            this.$navCtn.removeClass('submenu-open');
            this.$body.removeClass('no-scroll');
        }
    };



    // Triggers every time the user scrolls
    // Determines whether or not we should hide or show the menu automatically
    this.onScrollProgress = function(e) {
        var now = Date.now();
        var scrollPos = e.progress * this.siteHeight;
        var scrollSpeed;
        var scrollAmount;
        var delatTime;

        if(this.winWidth < this.BREAKPOINT) return;

        // The user started scrolling or changed scroll direction. Reset some vars
        if(e.scrollDirection !== this.scrollMetrics.prevScrollDir) {
            this.scrollMetrics.initScrollPos = scrollPos;
            this.scrollMetrics.prevTick = now;
            this.scrollMetrics.prevScrollDir = e.scrollDirection;
            return;
        }

        // How far has the user scrolled in this direction?
        scrollAmount = Math.abs(scrollPos - this.scrollMetrics.initScrollPos);

        // How fast is the user scrolling?
        delatTime = now - this.scrollMetrics.prevTick;
        scrollSpeed = scrollAmount / delatTime * 1000; // pixels/second

        // If we're above the point where the menu should always be in its visible state, force it to be visible
        if(scrollPos <= this.FORCE_SHOW_MENU_ABOVE && e.scrollDirection !== "FORWARD") {
            this.forcedMenuState = 'show';
            this.toggleShowMenu(true);
        
        // If we're below the point where the menu should always be in its hidden state AND we were previously in its forced hidden state, force it to be hidden 
        } else if(scrollPos >= this.FORCE_SHOW_MENU_ABOVE && this.forcedMenuState !== 'hide' && e.scrollDirection !== "REVERSE") {
            this.forcedMenuState = 'hide';
            this.toggleShowMenu(false);

        // Otherwise, trigger a state-change in the menu if we've either scrolled far enough and fast enough
        } else if(scrollPos > Math.max(this.FORCE_SHOW_MENU_ABOVE, this.FORCE_SHOW_MENU_ABOVE) && (scrollSpeed >= this.SCROLL_SPEED_TRIGGER || scrollAmount >= this.SCROLL_AMOUNT_TRIGGER)) {
            this.forcedMenuState = null;
            if(e.scrollDirection === 'FORWARD') {
                this.toggleShowMenu(false);
            } else if(e.scrollDirection === 'REVERSE') {
                this.toggleShowMenu(true);
            }
        }
        
        this.scrollMetrics.prevScrollDir = e.scrollDirection;
    };

    // Gets the document height. Necessary for the code that calculates scroll position.
    // TODO: It's possible that this is an expensive operation depending on how often ScrollMagic refreshes its "duration" parameter
    this.getDocHeight = function() {
        this.siteHeight = this.$body.outerHeight();
        return this.siteHeight;
    };


    this.onResize = function(e) {
        var w = this.$window.width();
        if(e !== undefined && w === this.winWidth) return;

        this.winWidth = w;

        if(w < this.BREAKPOINT) {
            this.toggleShowMenu(false);
        } else if(w >= this.BREAKPOINT && this.settings.showMenuOnLoad) {
            this.toggleShowMenu(true);
        } else if(w >= this.BREAKPOINT && this.settings.showMenuOnLoad !== true) {
            this.toggleShowMenu(false);
        }
    };
};



module.exports = component(GlobalNavbar);
