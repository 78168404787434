module.exports = BackgroundFit;
require ('../../vendor/bower_components/modernizr/feature-detects/css-objectfit.js');
function BackgroundFit() {
	if ( ! Modernizr['object-fit']) {
		$('.background-fit').each(function(index, element) {
			$(element).backgroundFit();
		});
	}
}

(function ( $ ) {
	$.fn.backgroundFit = function() {
    	var img = $(this).find('.background img').first();
		imgUrl = img.prop('src');

	    if (imgUrl) {
	     	$(this)
	      		.css('backgroundImage', 'url(' + imgUrl + ')')
	      		.css('backgroundSize', 'cover')
	      		.css('backgroundPosition', 'center top')
	      		.addClass('compat-object-fit');
	    }
	    return this
	};
}( jQuery ));
