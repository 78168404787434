require('../../vendor/bower_components/bootstrap-sass/assets/javascripts/bootstrap.js');
require('../../vendor/bower_components/jquery-validation/dist/jquery.validate.js'); //footer dependency
require('../../vendor/custom-plugin/dropkick.js'); //footer dependency
require('../../vendor/custom-plugin/jquery-ui-easing.js');// header , tween library dependency

var SVGInjector = require('../../vendor/bower_components/svg-injector/svg-injector.js');

var backgroundFit = require('../../lib/js/background-fit.js');

var GlobalNavbar = require('../../components/global-navbar/global-navbar-2.0.js');// navbar

var mobileNav = require('../../components/mobile-nav/mobile-nav.js');// navbar

var favIcons = require('../../components/favico/favico.js');

var searchResults = require('../../components/searchresult-item/searchresult-item.js');// navbar

var searchResultsSideNav = require('../../components/searchresults-left-menu/searchresults-left-menu.js');// navbar

var CookieDisclaimer = require('../../components/cookie-disclaimer/cookie-disclaimer.js');//footer

var footer = require('../../components/footer/footer.js');//footer

$(document).ready(function(){
	setGlobalNavSettings();
	updateNavForm();
	initNavForm();
	$('body').addClass('docready'); //all animations after this class
	$('.main-nav').attach(GlobalNavbar);
	$('.cookie-disclaimer').attach(CookieDisclaimer);
	SVGInjector($('img.svg'));
	backgroundFit();
	mobileNav();
	favIcons();
	searchResults();
	searchResultsSideNav();
	footer();
});

/**
 * None of the code below should be on any production html. It's only used for this demo.
 */
function setGlobalNavSettings() {
	var nav = $('.main-nav');
	var queryParams = parseQueryString();

	if(queryParams.sticky === 'true') {
		$('#sticky').prop('checked', true);
		nav.attr('data-sticky', 'true');
	}

	if(queryParams.collapse === 'true') {
		$('#collapse').prop('checked', true);
		nav.attr('data-collapse-menu-on-scroll', 'true');
	}

	if(queryParams['hide-menu'] === 'true') {
		$('#hide-menu').prop('checked', true);
		nav.attr('data-show-menu-on-load', 'false');
	} else {
		$('#hide-menu').prop('checked', false);
		nav.attr('data-show-menu-on-load', 'true');
	}

	if(queryParams['has-secondary'] === 'true') {
		$('#has-secondary').prop('checked', true);
		nav.attr('data-secondary-nav', 'true');
	}

	if(queryParams['secondary-sticky'] === 'true') {
		$('#secondary-sticky').prop('checked', true);
		nav.attr('data-secondary-nav-sticky', 'true');
	}

	switch(queryParams['display-mode']) {
		case 'opaque':
			nav.attr('data-transparent', 'false');
			nav.attr('data-text-color', 'null');
			break;

		case 'white':
			nav.attr('data-transparent', 'true');
			nav.attr('data-text-color', 'white');
			break;

		case 'black':
			nav.attr('data-transparent', 'true');
			nav.attr('data-text-color', 'black');
			break;
	}
	if(queryParams['display-mode']) {
		$('#display-mode').val(queryParams['display-mode']);
	}

	if(queryParams['bg-color'] === undefined) {
		queryParams['bg-color'] = 'purple';
	}
	nav.attr('data-bg-color', queryParams['bg-color']);
	$('#bg-color').val(queryParams['bg-color']);
}

function updateNavForm() {
	if($('#has-secondary').prop('checked')) {
		$('.secondary-nav-ctn').show();
		$('#secondary-sticky').prop('disabled', false);
	} else {
		$('.secondary-nav-ctn').hide();
		$('#secondary-sticky').prop('checked', false).prop('disabled', true);
	}

	if($('#secondary-sticky').prop('checked')) {
		$('#sticky').prop('checked', false).prop('disabled', true);
	}

	if(!$('#sticky').prop('checked')) {
		$('#collapse').prop('checked', false).prop('disabled', true);
	}
}


function initNavForm() {
	$('#has-secondary').change(function() {
		var input = $(this);
		if(input.prop('checked')) {
			$('#secondary-sticky').prop('disabled', false);
			$('#sticky').prop('checked', false).prop('disabled', true);
		} else {
			$('#secondary-sticky').prop('checked', false).prop('disabled', true);
			$('#sticky').prop('disabled', false);
		}
	});

	$('#secondary-sticky').change(function() {
		var input = $(this);
		if(input.prop('checked')) {
			$('#sticky').prop('checked', false).prop('disabled', true);
		} else {
			$('#sticky').prop('disabled', false);
		}
	});

	$('#sticky').change(function() {
		var input = $(this);
		if(input.prop('checked')) {
			$('#collapse').prop('disabled', false);
		} else {
			$('#collapse').prop('checked', false).prop('disabled', true);
		}
	});
}

function parseQueryString() {
    var params = {},
        hashString = location.hash.substring(1),
        queryString = location.search.substring(1),
        regex = /([^&])+[^&]/g,
        split,
        m;
    var indexQMark = hashString.indexOf('?');
        if(indexQMark !== -1) {
        queryString = hashString.substr(indexQMark+1);
        hashString = hashString.substring(0, indexQMark);
    }

    while (m = regex.exec(hashString)) {
        split = m[0].split('=');
        if(split.length < 2) split[1] = null;
        params[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }

    while (m = regex.exec(queryString)) {
        split = m[0].split('=');
        if(split.length < 2) split[1] = null;
        params[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }

    return params;
}