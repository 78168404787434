/*
	Array.diff function
	courtesy of solution found at http://stackoverflow.com/questions/12433604/how-can-i-find-matching-values-in-two-arrays
	usage: array1.diff(array2);
*/
Array.prototype.diff = function(arr2) {
  var ret = [];
  for(i in this) {
      if(arr2.indexOf( this[i] ) > -1){
          ret.push( this[i] );
      }
  }
  return ret;
};

module.exports = (function() {
	/* price formatter global fn.
	converts $99.99 for example, to $99 with a superscript 99 and no dot separator.
	usage: formatPrice(69.99) returns 69<sup>99</sup>
	*/
	function formatPrice(price) {
	  if(price.indexOf('.') != -1 && price.indexOf('-') == -1){
	  	if ($('.price').html().indexOf('Rp') != -1 ){
	  		if(price.indexOf('wholeamount') != -1){
	  			return price;
	  		} else {
	  			return price.replace(',','.');
	  		}
	  	}
	  	else
	  		return price;
	  }
	  else if((price.indexOf('.') != -1 && price.indexOf('-') > 0) || (price.indexOf(',') != -1 && price.indexOf('-') > 0)){
		var pricelow = price.split("-")[0];
		var pricehigh = price.split("-")[1];
		return pricelow+" - "+pricehigh;
	  }
	  else if(price.indexOf(',') != -1 && price.indexOf('-') == -1){
	  	return price;
	  }
	}

  /* debounce global fn. throttles function calls */
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    }
  }

  // placeholder polyfill for IE9
  function placeholder(){
    if (!Modernizr.input.placeholder) {
      $("[placeholder]").each(function() {
        var initial_value, input;
        input = $(this);
        initial_value = input.attr("placeholder");
        input.val(initial_value);
        input.focus(function() {
          if (input.val() === input.attr("placeholder")) {
            return input.val("");
          }
        });
        return input.blur(function() {
          if (input.val() === "") {
            return input.val(initial_value);
          }
        });
      });
    }
  }

  function hasOwnProperty(object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
  }


  /**
   * Convert a string to hyphenated lowercase string.
   * @param {String} string Variable case string.
   * @return {String} Normalized string.
   */
  var singleQuoteRegExp = /'/g;
  var validCharRegExp = /[^a-z0-9]+/g;
  var whiteSpaceRegExp = /\s+/g;
  function normalize(string) {
    return string.replace(singleQuoteRegExp, '')
      .toLowerCase()
      .replace(validCharRegExp, ' ')
      .trim()
      .replace(whiteSpaceRegExp, '-');
  }


  /**
   * Safe way to remove YouTube iframe.
   * Read more: http://johnbeales.com/2014/really-purging-a-youtube-iframe-in-internet-explorer/#ixzz3gRaER48C
   * @param  {Object} modal iFrame parent element.
   * @return {[type]}       [description]
   */
  function purgeVideo (modal) {
  // purge video must be called while the video is still displayed on the page,
  // so doing a jQuery fadeOut(purgeVideo) may throw errors, (I think because
  // fadeOut sets display:none before calling its callback).
    if( modal ) {
      // get the iframe, declare variables
      var ifr = $('iframe', modal), rifr;

      // if the iframe is found get the underlying DOM node from jQuery
      if ( ifr.length > 0 ) {
        rifr = ifr.get(0);
      }

      // make extra sure the DOM node exists before starting to work on it.
      if( rifr ) {
        // set src to '' to start removing YouTube and get around cross-domain access restrictions
        rifr.src = '';
        // now set the document element of the iframe to null, (I think this is IE-specific)
        if ( rifr.documentWindow ) {
          rifr.documentWindow.document = null;
          rifr.documentWindow = null;
        }
        // delete the iframe, (also removes it from DOM).
        delete rifr;
      }

      // this won't throw an error, even if the iframe is gone, (we're back to jQuery here).
      ifr.remove();

      // remove anything else, (titles, etc), from the modal overlay
      modal.html('');

    }
  }


  //get the proper viewport size that will match the media query
  function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };

  }

  function url() {
    return {
      getVars: function()
        {
          return this.parseVars(this.getQuery(window.location.href));
        },
        parseVars:function(url){
          var vars = [], hash;
            var hashes = url.slice(url.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                if (hash.length == 2){
                  vars[hash[0]] = decodeURIComponent(hash[1]);
                } else {
                  vars[hash[0]] = true;
                }
            }
            return vars;
        },
        removeParameter:function(url, parameter) {
          return url
            .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
            .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
        },
        getQuery:function(url){
          var qs = '';
          if (url.indexOf('?') > 0){
            qs = url.slice(url.indexOf('?') + 1)
          }

          return qs;
        }
      }
  }

  return {
    formatPrice: formatPrice,
    debounce: debounce,
    placeholder: placeholder,
    hasOwnProperty: hasOwnProperty,
    normalize: normalize,
    purgeVideo: purgeVideo,
    viewport: viewport,
    url: url
  };

}());
