
module.exports = function() {
  $(function(){

	  var $body = $("body"),
	  		$navIconMobile = $("#nav-icon-mobile"),
	  		$mobileNavMenu = $("#mobile-nav-menu"),
	  		$mobileNavLinks = $mobileNavMenu.find('ul.links>li>a');
	  		$mobileNav = $("#mobile-nav"),
	  		$mobileLogo = $("#mobile-logo"),
            $searchWidget = $('#mobile-nav .search-widget');

 		$navIconMobile.on("click", function(event) {
            // If mobile nav is open, CLOSE it...
			if($mobileNav.hasClass("open")) {
				$body.removeClass("no-scroll");
				$mobileNav.removeClass('open');
			  	$mobileNavMenu.addClass('hidden');
			  	$mobileNav.find('li.open').removeClass('open');
                if (!$('body').hasClass('home')) {
                    document.body.style.overflowY = "auto";
                    // Stop listening for tap outside to close portrait-to-landscape search.
                    // $('body').off('mouseup touchend');
                }
                // Close search widget.
                $searchWidget.removeClass('open');
                $('.scroll-list').removeClass('hidden');
                // Unhide global search icon.
                $globalNav.find('a.show-search').removeClass('hidden');
            // If mobile nav is closed, OPEN it...
		 	}else{
		 		$body.addClass("no-scroll");
		 		$mobileNav.addClass('open');
		  		$mobileNavMenu.removeClass("hidden");
                if (!$('body').hasClass('home')) {
                    document.body.style.overflowY = "hidden";
                }
		  }
 		});

 		$mobileNavLinks.on('click', function(evt){
 			evt.preventDefault();
 			var parent = $(this).parent();
 			var isOpen = parent.hasClass('open');
 			if (isOpen === true){
 				parent.removeClass('open');
 			} else {
 				$mobileNav.find('li.open').removeClass('open');
 				parent.addClass('open');
 			}
 		})

 		// MOBILE SEARCH
        var $gSearchWidget = $('#global-navbar .search-widget');
        var $mSearchWidget = $('#mobile-nav .search-widget');
        var $globalNav = $('#global-navbar');   // For global.
        // Click mag glass to show search.
        $mobileNav.find('a.show-search').on('click', function(event){
            event.preventDefault();

            // If search widget is hidden, show search.
            if (!$searchWidget.hasClass('open')) {
                $searchWidget.addClass('open');
    	        $searchWidget.find('input[type="text"]').focus();
                $globalNav.find('a.show-search').addClass('hidden');
                $('.scroll-list').addClass('hidden');
                // In case of rotation from portrait to landscape on tablet, listen for tap outside to close search.
                $('body').on('mouseup touchend', function(e) {
                    if (!$gSearchWidget.is(e.target) && !$gSearchWidget.has(e.target).length && !$('#mobile-nav a.show-search').is(e.target) && !$mobileNav.has(e.target).length) {
                        // Close global and mobile search widgets.
                        $globalNav.find('a.show-search').removeClass('hidden');
                        $searchWidget.removeClass('open');
                        $('#mobile-nav .search-widget .typeahead').blur();
                        // Restore mobile nav to default and hide.
                        $('.scroll-list').removeClass('hidden');
                        $mobileNav.removeClass('open');
                        $mobileNavMenu.addClass('hidden');
                        $mobileNav.find('li.open').removeClass('open');
                        // $('body').off('mouseup touchend');
                    }
                });
                resetResultsH();
            // If search widget is visible, hide search.
            } else {
                $searchWidget.removeClass('open');
                $('#mobile-nav .search-widget .typeahead').blur();
                $('.scroll-list').removeClass('hidden');
                // Stop listening for tap outside to close portrait-to-landscape search.
                // $('body').off('mouseup touchend');
                // Unhide global search icon.
                $globalNav.find('a.show-search').removeClass('hidden');
            }
        })

        $mobileNav.find('a.close-search').on('click', function(event){
            event.preventDefault();
            // Clear input val.
            $('#mobile-nav .search-widget .typeahead').typeahead('val', '');
            $('#global-navbar .search-widget .typeahead').typeahead('val', '');
            // focus cursor
            $searchWidget.find('input[type="text"]').focus();
            // Clear/hide results.
            $('.search-widget .results').empty();
            $('.search-widget .results').addClass('hidden');
            $('.search-widget .results').removeClass('show');
        })

        // resetResultsH() called when device is rotated, similar to setResultsH() global-navbar.js.
        function resetResultsH() {
            var results = $('.search-widget .results');
            if (results.html() == '') {
                return;
            }
            var viaMobileNav = false;
            var viewportH = $(window).height();
            var $gSeeAll = $('#global-navbar .see-all');
            var $gResults = $('#global-navbar .results');
            var $gttMenu = $('#global-navbar .tt-menu');
            // maxResultsH = height of result products + SEE ALL link.
            var maxResultsH = $gSeeAll.position().top + $gSeeAll.outerHeight() + $gResults.scrollTop() - $gResults.position().top + 1;
            if ($gttMenu.css('display') == "block") {
                maxResultsH = maxResultsH - $gttMenu.height();
            }
            var sWidgetH = $('#global-navbar .search-widget .form').height() +14;
            if ($('#mobile-nav').hasClass('open')) {
                viaMobileNav = true;
            }
            // If using mobile nav...
            if ((aspect == "portrait") || (viaMobileNav)) {
                // Use mobile search calculation to set height.
                viewportH =  (typeof window.innerHeight != 'undefined')?Math.min(window.innerHeight, $(window).height()):$(window).height();
                sWidgetH = $('#mobile-nav .search-widget .form').height() + 14;
                var sWidgetY = $('#mobile-nav .search-widget .form').offset().top;
                var ttMenuH = $('#mobile-nav .search-widget .tt-menu').height();
                ttMenuH = 0;
                var scrollWindowH = viewportH - (sWidgetH + sWidgetY - ttMenuH);
                $('.scroll-results').css('height', scrollWindowH);
                // alert("mobile-nav.js :: resetResultsH() :: MOBILE scrollWindowH: "+scrollWindowH);
                // alert("viewportH: "+viewportH+" | sWidgetH: "+sWidgetH+" | sWidgetY: "+sWidgetY);
            // Otherwise, using global nav...
            } else {
                viewportH =  (typeof window.innerHeight != 'undefined')?Math.min(window.innerHeight, $(window).height()):$(window).height();
                sWidgetY = $('#global-navbar .search-widget .form').offset().top - $(window).scrollTop();
                scrollWindowH = viewportH - (sWidgetH + sWidgetY) - 0;// Subtract margin between bottom of results and browser bottom edge.
                scrollWindowH = Math.min(scrollWindowH, maxResultsH);
                $('#global-navbar .results').css('height', scrollWindowH);
            }
            // alert("mobile-nav.js :: resetResultsH() :: "+scrollWindowH+" : "+viewportH+" : "+sWidgetH+" : "+sWidgetY);
        }
 		// MOBILE SEARCH

        var aspect = "";
 		function deviceReorientation() {// Detect change between Landscape & Portrait
 			// Hide subnavs.
 			var parent = $mobileNavLinks.parent();
 			var isOpen = parent.hasClass('open');
 			if (isOpen === true){
 				parent.removeClass('open');
 			}

            readDeviceOrientation();

            // $('html,body').scrollTop(0);
            // If search widget is open...
            if ($('#mobile-nav .search-widget').hasClass('open') || $('#global-navbar .search-widget').hasClass('open')) {
                // Close global and mobile search widgets.
                $globalNav.find('a.show-search').removeClass('hidden');
                $searchWidget.removeClass('open');
                $('#mobile-nav .search-widget .typeahead').blur();
                // Restore mobile nav to default and hide.
                $('.scroll-list').removeClass('hidden');
                $mobileNav.removeClass('open');
                $mobileNavMenu.addClass('hidden');
                $mobileNav.find('li.open').removeClass('open');
                // Stop listening for tap outside to close portrait-to-landscape search.
                // $('body').off('mouseup touchend');
                // Resize scrolling results (just in case there are any).
                resetResultsH();
            }

            if (!$('#mobile-nav').hasClass('open')){
            	 $body.removeClass('no-scroll').css('overflowY', 'auto');
            }
            // Specific to page piling element, to enable return to pagepiling from category tiles.
            //
            //
            //if ((aspect == "landscape") && $('body').hasClass('home')) {
            //if ((aspect == "landscape") && $('#panel-stack').length > 0) {
            if ($('#panel-stack').length > 0) {

                if($('#panel-stack').hasClass('hide-pagepiling')) {
                    $navIconMobile.removeClass('show-nav');
                    $('body').removeAttr('style');
                }
                // Force mobile nav closed (from mobile-nav.js close code above)...
                $body.removeClass("no-scroll");
                $mobileNav.removeClass('open');
                $mobileNavMenu.addClass('hidden');
                $mobileNav.find('li.open').removeClass('open');
                // Close search widget.
                $searchWidget.removeClass('open');
                $('.scroll-list').removeClass('hidden');
                // Unhide global search icon.
                $globalNav.find('a.show-search').removeClass('hidden');
            }
 		}
 		function readDeviceOrientation() {// Landscape vs. Portrait
 			if (Math.abs(window.orientation) === 90) {
 				// Landscape
                aspect = "landscape";
 			} else {
 				// Portrait
                aspect = "portrait";
 			}
 		}

 		//window.addEventListener("orientationchange", deviceReorientation);
        $(window).on("orientationchange",deviceReorientation);

	});
};
