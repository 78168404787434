module.exports = function() {
	
	// Vertically center item arrows.
    $('.searchresults-menu-item').each(function() {
    	var $arrow = $(this).find('.arrow-right');
	    var arrowY = ($(this).height() - $arrow.outerHeight())/2;
	    $arrow.css('top', (arrowY));
    });
    
	$(".searchresults-menu-item").click(function() {
	    $('.searchresults-menu-item').removeClass('active');
	    $(this).addClass('active');
	});
    
};
    