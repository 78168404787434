var component = require('../../lib/js/component');
var Cookies = require('../../vendor/bower_components/cookies-js/dist/cookies.js');

function CookieDisclaimer() {

  this.initialize = function() {

    this.on('click', '.dismiss', this.handleClick);

    if (this.getCookie()) {
      this.close();
    }
    this.$element.addClass('initialized');    
  };


  this.setCookie = function() {
    Cookies.set('logitechEUdisclaimer', 'viewedagreedd'); 
    return false;
  };
  

  this.getCookie = function() {
    if (Cookies.get('logitechEUdisclaimer','viewedagreedd')) {
     return true
   }
   return false;
 };


 this.handleClick = function(event) {
    this.setCookie();
    this.close();
  };
  
  
  this.close = function() {
    this.$element.addClass('hidden');
    this.off('click', 'button.close', this.handleClose);
  };
}

module.exports = component(CookieDisclaimer);
