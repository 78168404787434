/*
favico.js : dynamic refresh of the favicon
custom event to refresh the favicon color based on the panels (homepage) or navbar or default
*/

module.exports = function() {
// init event 
var favImagePath = "/images/favicons/";
var favColors = ["blue-bg,logi-favicon_32x32_blue.png", 
				"teal-bg,logi-favicon_32x32_ciano.png", 
				"yellow-green-bg,logi-favicon_32x32_yellow.png",
				"red-bg,logi-favicon_32x32_red.png",
				"light-blue-bg,logi-favicon_32x32_blue.png",
				"pink-bg,logi-favicon_32x32_red.png",
				"purple-bg,logi-favicon_32x32_lila.png"];
var randomIfNoBg = true;

// random default favicon or predefined color
//var defaultFavColor = favColors[0];
if (randomIfNoBg) var defaultFavColor = favColors[Math.floor((Math.random() * favColors.length))];

document.head || (document.head = document.getElementsByTagName('head')[0]);

// newMessage event subscribers
$(document).on("favIconEv", newMessageHandler);

// update favicon color
function updateFavico(favImagePath) {
	var link = document.createElement('link'),
     	oldLink = document.getElementById('dynamic-favicon');
	//jQuery('link[rel="shortcut icon"]')
	//changeFavicon(favImagePath);
 	link.id = 'dynamic-favicon';
 	link.rel = 'shortcut icon';
 	link.href = favImagePath;
 	if (oldLink) {
  	document.head.removeChild(oldLink);
 	}
 	document.head.appendChild(link);
 	$('link[type="image/x-icon"]').remove();
	$('link[rel="shortcut icon"]').prop('href',favImagePath);
    $("#favicon").attr("href",favImagePath); 
}
 
// newMessage event handler
function newMessageHandler(e) {
	var classList =	e.message.className.split(/\s+/);
	var defaultClassList = e.defaultClass.split(/\s+/);
	var favImagePathFull = favImagePath + chooseColor(classList,defaultClassList);
}

// identify color based on background colors in global-nav or sections
function chooseColor(classList,defaultClassList) {
	var classColor = getColorClass(classList);
	var selectedColor = '';
	if (classColor == '') classColor = getColorClass(defaultClassList.split(','));
	if (classColor == '') classColor = favColors[0].split(',');
	
	$.each(favColors, function(indexc, itemc) {
		var m = itemc.split(',')[0].toLowerCase().trim() === classColor.toLowerCase().trim();
		if (m && selectedColor.length === 0) selectedColor = itemc.split(',');
	});
	var favImagePathFull = favImagePath + (selectedColor === "" ? defaultFavColor.split(',')[1] : selectedColor[1]);
	//var favImagePathFull = favImagePath + selectedColor[1];
	updateFavico(favImagePathFull);
}

// extract color -bg from class list
function getColorClass(classList) {
 	var ret = '';
	$.each(classList, function(index, item) {
		var n = item.search(/\-bg/i);
		if(n>-1 && ret=='') ret = item; 
	});
	return ret; 
}

// init default color favicon
$(document).ready(function(){
	// disable update with piling-panels pages
	if (!$("body").hasClass("piling-panels")) {
		var val = defaultFavColor.split(',')[0];
		var navbarClass = $('#global-navbar').attr('class');
		val = (typeof navbarClass === "undefined" || navbarClass.length < 1) ? defaultFavColor.split(',')[0] : navbarClass;
		//if (val.length < 1) val = defaultFavColor.split(',')[0];
		var arrVal = val.split(' ');
		chooseColor(arrVal,defaultFavColor);
	}
});
}






